<template>
  <default>
    <v-chip-group mandatory active-class="primary">
      <v-chip  active-class="false" :to="{ name: 'Dashboard' }">Dashboard</v-chip><span class="tw-mt-2 tw-mr-2 primary--text"> > </span>
      <v-chip active-class="false" :to="{ name: 'Products' }">Products</v-chip><span class="tw-mt-2 tw-mr-2 primary--text" > > </span>
      <v-chip class="white--text" color="primary" :to="{ name: 'IndividualPensionPlan'}">Individual Pension Plan</v-chip>
    </v-chip-group>
    <v-card elevation="2" class="tw-px-2 md:tw-px-6 tw-mt-2 md:tw-mt-10">
      <card-title icon="/images/icons/top-icon.png">
        Individual Pension Plan Calculator
      </card-title>
      <div class="tw-w-full tw-flex tw-flex-wrap tw-py-3 md:tw-py-6">
        <keep-alive>
          <component
          :is="current"
          :wealthbuilder="wealthbuilder"
          :benefits="benefits"
          :productCode = "productData.prodCode"
          :optionCode = "productData.optionCode"
          :terms="terms"
          :quoteSummaryData="quoteSummaryData"
          :frequency="IPPfrequency"
          @fetchBenefits="fetchBenefits"
          >
          </component>
        </keep-alive>
      </div>
    </v-card>
  </default>
</template>

<script>
import Default from '../../layouts/Default.vue';
import Quote from '../../components/rfq/wealthbuilder/Quote.vue';
import CardTitle from '../../components/CardTitle';
import BackButton from '../../components/BackButton';
import ProductMixin from '../../mixins/ProductMixin';
import savedQuoteMixin from '../../mixins/savedQuoteMixin';

export default {
  mounted() {
    this.fetchProductOptions();
    this.fetchBenefits();
  },
  name: 'IndividualPensionPlan',
  components: {
    Default, Quote, BackButton, CardTitle,
  },
  mixins: [ProductMixin, savedQuoteMixin],
  data() {
    return {
      wealthbuilder: false,
      buttons: [{ title: 'Calculate', icon: 'calculator' }, { title: 'Email Quote', icon: 'at' }, { title: 'Download PDF', icon: 'download' }],
      current: Quote,
      productData: { prodCode: 2013106, optionCode: 2012150 },
      benefits: [],
      productOptions: [],
      terms: [],
      quoteSummaryData: {},
      termLimit: {
        min: 5,
        max: 100,
      },
      IPPfrequency: ['Annually', 'Semi Annually', 'Monthly', 'Quarterly'],
    };
  },
};
</script>
